import { defineStore } from "pinia";
import { computed, ref } from "vue";
export const useSocketStore = defineStore('socket', () => {
    const socket_id = ref(null)
    function setSocketId(id) {
        socket_id.value = id;
    }
    const getSocketId = computed(() => socket_id.value)


    return { socket_id, setSocketId, getSocketId }
})
