import { can, RoleOrCan, hasRole } from '@/Composable/usePermission'
export default {
    install: (app) => {

        app.config.globalProperties.can = can;
        app.config.globalProperties.hasRole = hasRole;
        app.config.globalProperties.RoleOrCan = RoleOrCan;


        app.mixin({
            methods: {
                can: can,
                hasRole: hasRole,
                RoleOrCan: RoleOrCan,

            },
        });

        app.provide('can', can)
        app.provide('hasRole', hasRole)
        app.provide('RoleOrCan', RoleOrCan)
    }
}
