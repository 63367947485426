


import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import { useSocketStore } from "@/stores/socket";

const permission = computed(() => Object.keys(usePage().props.can ?? {}) ?? [])
const roles = computed(() => usePage().props.userRoles ?? [])
const features = computed(() => usePage().props.features ?? {})
const settings = computed(() => usePage().props.setting ?? {})


export const is_connected_socket = computed(() => !!useSocketStore().socket_id);

/**
 * looks for an specific item of the list.
 * https://facebook.com/teto.tals
 * @param {String | Array<String>} item Array to search from.
 * @returns {Boolean} item found.
 */

export const is_enabled_setting = (item) => {

    // if item not null
    if (item) {
        // if item is have in key of settings
        if (settings.value?.hasOwnProperty(item)) {
            // if item is true
            if (settings.value[item] != '0' && settings.value[item] != 'false') {
                return true;
            }
        }
    }

    return false;

}

export const is_enabled_module = (item) => {

    // if item not null
    if (item) {
        // if item is have in key of features
        if (features.value?.hasOwnProperty(item)) {
            // if item is true
            if (features.value[item]) {
                return true;
            }
        }
    }

    return false;

}
export const can = (item) => {

    if (hasRole('super-admin')) {
        return true;
    }

    if (typeof item == 'string') {
        return permission.value.includes(item);
    } else if (typeof item == 'object' || typeof item == 'array') {
        for (const vv in item) {
            if (Object.hasOwnProperty.call(item, vv)) {
                if (permission.value.includes(item[vv])) {
                    return true;
                    break;
                }
            }
        }
    }

    return false;
}
export const hasRole = (item) => {


    if (typeof item == 'string') {
        return roles.value.includes(item);
    } else if (typeof item == 'object' || typeof item == 'array') {
        for (const vv in item) {
            if (Object.hasOwnProperty.call(item, vv)) {
                if (roles.value.includes(item[vv])) {
                    return true;
                    break;
                }
            }
        }
    }

    return false;
}
export const RoleOrCan = (item) => {

    return can(item) || hasRole(item);

}
